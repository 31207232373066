
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": "Dashboard",
    "icon": "pi pi-microsoft",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "",
    "label": "Daftar Tuntutan",
    "icon": "pi pi-directions",
    "iconcolor": "",
    "target": "_parent",
    "items": [
      {
        "to": "/datapetugas/ubbm/kodpep/02",
        "label": "Ujian Bertutur BM",
        "icon": "pi pi-angle-right",
        "iconcolor": "",
        "target": "_parent",
        
      },
      {
        "to": "/datapetugas/uas/kodpep/01",
        "label": "Ujian Amali",
        "icon": "pi pi-angle-right",
        "iconcolor": "",
        "target": "_parent",
        
      },
      {
        "to": "/datapetugas/ubbi/kodpep/03",
        "label": "Ujian Bertutur BI",
        "icon": "pi pi-angle-right",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/datapetugas/umub/kodpep/04",
        "label": "Ujian Mendengar dan Bertulis",
        "icon": "pi pi-angle-right",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/datasukarelawan",
        "label": "Sukarelawan SPM",
        "icon": "pi pi-angle-right",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "/menu_semak",
    "label": "Semak Tuntutan",
    "icon": "pi pi-desktop",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/laporan",
    "label": "Laporan Daerah",
    "icon": "pi pi-server",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/laporan_keseluruhan",
    "label": "Laporan Keseluruhan",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/menu_setting",
    "label": "Konfigurasi",
    "icon": "pi pi-cog",
    "iconcolor": "",
    "target": "",
    
  }
],
	daerah: [    

    ],
	status: [    
{value: "AKTIF", label: "Aktif"},
	{value: "TIDAK AKTIF", label: "Tidak Aktif"}
    ],
	bulan: [    
{value: "JANUARI", label: "JANUARI"},
	{value: "FEBRUARI", label: "FEBRUARI"},
	{value: "MAC", label: "MAC"},
	{value: "APRIL", label: "APRIL"},
	{value: "MEI", label: "MEI"},
	{value: "JUN", label: "JUN"},
	{value: "JULAI", label: "JULAI"},
	{value: "OGOS", label: "OGOS"},
	{value: "SEPTEMBER", label: "SEPTEMBER"},
	{value: "OKTOBER", label: "OKTOBER"},
	{value: "NOVEMBER", label: "NOVEMBER"},
	{value: "DISEMBER", label: "DISEMBER"}
    ],
	statusBorang: [    
{value: "LENGKAP", label: "LENGKAP"},
	{value: "TIDAK LENGKAP", label: "TIDAK LENGKAP"},
	{value: "KUIRI", label: "KUIRI"}
    ],
	statusBayaran: [    
{value: "DALAM PROSES", label: "DALAM PROSES"},
	{value: "PERAKU 2", label: "PERAKU 2"}
    ],
	statusBayaran2: [{"label":"DALAM PROSES","value":"DALAM PROSES"},{"label":"PERAKU 2","value":"PERAKU 2"}],
	jenUmub: [{"label":"IKHTISAS MENDENGAR","value":"IKHTISAS MENDENGAR"},{"label":"IKHTISAS BERTULIS","value":"IKHTISAS BERTULIS"}],
	menusettingMenuSettingStaticmenu: [
  {
    "to": "/datadaerah/",
    "label": "Senarai Daerah",
    "icon": "pi pi-image",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/datajawatan/",
    "label": "Senarai Jawatan",
    "icon": "pi pi-prime",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/datapetugas/",
    "label": "Senarai Petugas",
    "icon": "pi pi-users",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/datapusat/",
    "label": "Senarai Pusat Peperiksaan",
    "icon": "pi pi-building",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/datasekolah/",
    "label": "Senarai Sekolah",
    "icon": "pi pi-home",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/datasukarelawan/",
    "label": "Senarai Sukarelawan",
    "icon": "pi pi-users",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/permissions/",
    "label": "Permission",
    "icon": "pi pi-ticket",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/roles/",
    "label": "Peranan",
    "icon": "pi pi-android",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/users/",
    "label": "Senarai Pengguna",
    "icon": "pi pi-prime",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtnt/",
    "label": "Setting Tuntutan",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  }
],
	menusemakMenuSemakStaticmenu: [
  {
    "to": "/tbtuntutan/ikhtisas/",
    "label": "TUNTUTAN IKHTISAS",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtuntutan/perjalanan/jen_tnt/TUNTUTAN PERJALANAN",
    "label": "TUNTUTAN PERJALANAN",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  }
],
	menusemakMenuSemakStaticmenu2: [
  {
    "to": "/tbtuntutansukarelawan/",
    "label": "TUNTUTAN IKHTISAS DAN PERJALANAN",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  }
],
	laporanLaporanStaticmenu: [
  {
    "to": "/tbtuntutan/listlaporan/daerah/BATANG PADANG",
    "label": "BATANG PADANG",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtuntutan/listlaporan/daerah/KINTA UTARA",
    "label": "KINTA UTARA",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtuntutan/listlaporan/daerah/KERIAN",
    "label": "KERIAN",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtuntutan/listlaporan/daerah/MANJUNG",
    "label": "MANJUNG",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtuntutan/listlaporan/daerah/KUALA KANGSAR",
    "label": "KUALA KANGSAR",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtuntutan/listlaporan/daerah/HILIR PERAK",
    "label": "HILIR PERAK",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtuntutan/listlaporan/daerah/LARUT MATANG SELAMA",
    "label": "LARUT MATANG SELAMA",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtuntutan/listlaporan/daerah/HULU PERAK",
    "label": "HULU PERAK",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtuntutan/listlaporan/daerah/PERAK TENGAH",
    "label": "PERAK TENGAH",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtuntutan/listlaporan/daerah/KINTA SELATAN",
    "label": "KINTA SELATAN",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtuntutan/listlaporan/daerah/BAGAN DATUK",
    "label": "BAGAN DATUK",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtuntutan/listlaporan/daerah/MUALLIM",
    "label": "MUALLIM",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  }
],
	laporanLaporanStaticmenu2: [
  {
    "to": "/tbtuntutansukarelawan/listlaporansuk/daerah/BATANG PADANG",
    "label": "BATANG PADANG",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtuntutansukarelawan/listlaporansuk/daerah/KINTA UTARA",
    "label": "KINTA UTARA",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtuntutansukarelawan/listlaporansuk/daerah/MANJUNG",
    "label": "MANJUNG",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtuntutansukarelawan/listlaporansuk/daerah/KERIAN",
    "label": "KERIAN",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtuntutansukarelawan/listlaporansuk/daerah/KUALA KANGSAR",
    "label": "KUALA KANGSAR",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtuntutansukarelawan/listlaporansuk/daerah/HILIR PERAK",
    "label": "HILIR PERAK",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtuntutansukarelawan/listlaporansuk/daerah/LARUT MATANG SELAMA",
    "label": "LARUT MATANG SELAMA",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtuntutansukarelawan/listlaporansuk/daerah/HULU PERAK",
    "label": "HULU PERAK",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtuntutansukarelawan/listlaporansuk/daerah/PERAK TENGAH",
    "label": "PERAK TENGAH",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtuntutansukarelawan/listlaporansuk/daerah/KINTA SELATAN",
    "label": "KINTA SELATAN",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtuntutansukarelawan/listlaporansuk/daerah/BAGAN DATUK",
    "label": "BAGAN DATUK",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/tbtuntutansukarelawan/listlaporansuk/daerah/MUALLIM",
    "label": "MUALLIM",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  }
],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}